import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant"; 
import Testimonials from "../../Components/Elements/testimonials";
import Certification from "../../Components/Elements/certification";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import FeaturedCategories from "../../Components/Elements/featured_categories";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import GridBannerSecond from "../../Components/Elements/grid_banner_second";
import FeaturedVideoProducts from "../../Components/Elements/featured_video_products";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Helmet } from "react-helmet";
import SecondCategoryWiseProducts from "../../Components/Elements/second_category_wise_product";
import GridBannerThird from "../../Components/Elements/grid_banner_third";
import Blogs from "../../Components/Elements/blogs";
import VideoSlider from "../../Components/Elements/video_slider";
import Manufacturing from "../../Components/Elements/manufacturing";
function Home() { 
const [spinnerLoading, setSpinnerLoading] = useState(true);
const didMountRef = useRef(true); 
const dataArray = sessionCartData();
const parsedCartSession = dataArray[1];
const [cartCount, setCartCount] = useState(parsedCartSession.length);
const [pageData , setPageData] = useState("")
useEffect(() => {
if(didMountRef.current){
setTimeout(() => {
setSpinnerLoading(false);
}, 1000);

const getPageData = {
slug: "home",
};
ApiService.postData("page-content", getPageData).then((res) => {
if (res.status == "success") {
setPageData(res.data)

} else {

}
});
}
didMountRef.current = false;
}, []);
const handleCartCount = (status) => {
const dataArray = sessionCartData();
const parsedCartSession = dataArray[1];
setCartCount(parsedCartSession.length)
};
return (
<>
<Helmet>
<title>{pageData.page_meta_title}</title>
<meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :"Triveni Exports"} />
{pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:""}
<link rel="canonical" href={window.location.href} />
<meta property="og:title" content={pageData.page_meta_title} />
<meta property="og:image" content= {constant.FRONT_URL+'img/logo.png'}/>
<meta property="og:url" content={window.location.href} />
<meta property="og:description" content= {pageData.page_meta_desc != null ?pageData.page_meta_desc:"Triveni Exports"} />
<meta name="twitter:title" content={pageData.page_meta_title} />
<meta name="twitter:description" content={pageData.page_meta_desc != null ?pageData.page_meta_desc:"Triveni Exports"} />
<meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />
<meta name="twitter:url" content={window.location.href} />
</Helmet>
{spinnerLoading && <SpinnerLoader />}
<Header cartCount={cartCount}/>
<HomeTopBanner /> 
<FeaturedCategories />
<GridBannerFirst /> 
<TagWiseProducts onParentData={handleCartCount}/>
<FeaturedVideoProducts />
{/* <VideoSlider/> */}
<CategoryWiseProducts onParentData={handleCartCount}/>
<GridBannerSecond />
<SecondCategoryWiseProducts onParentData={handleCartCount} />
<GridBannerThird />
<Testimonials />
<Certification />
<Manufacturing/>
<Blogs/>
<Footer />
</>
);
}
export default Home;
