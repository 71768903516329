import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import Skeleton from "react-loading-skeleton";
function Certification() {
  const didMountRef = useRef(true);

  const [resCertificate, setResCertificate] = useState([]);
  const [certificateImagePath, setCertificateImagePath] = useState([]);
  const [loading, setLoading] = useState()
  const breakpoints = {

    320: {
      slidesPerView: 1
    },

    480: {
      slidesPerView: 2
    },

    768: {
      slidesPerView: 4
    },

    1024: {
      slidesPerView: 3
    }
  };
  useEffect(() => {
    if (didMountRef.current) {
      getCertificateData();
    }
    didMountRef.current = false;
  }, []);
  const getCertificateData = () => {
    setLoading(true)
    ApiService.fetchData("featured-certificate").then((res) => {
      if (res.status == "success") {
        setResCertificate(res.resCertificate);
        setCertificateImagePath(res.certificate_image_path);
        setLoading(false)
      }
      else {
        setLoading(false)
      }
    });
  };

  return (
    <>
      {
        loading == true ? <>
          <section className="sec-pad-large">
            <div className="container">
              <div className="row">
                <div className="section-title text-center mb-5">
                  <h2><Skeleton width={200} /></h2>
                </div>
              </div>
              <div className="row">
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 6,
                }
              }}
            >
              {[...Array(12)].map((_, index) => {
                return (
                  <SwiperSlide key={index}>
                  <div className="col mx-2">
                    <div key={index}>
                      <Skeleton width='100%' height={100} />
                    </div>
                  </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
              </div>
            </div>
          </section>
        </> : resCertificate.length > 0 && (
        <section className="sec-pad-large">
          <div className="container">
            <div className="section-title text-center mb-5">
              <h2>Certificate & Awards</h2>
              <span class="shape-line"><i class="icon-19"></i></span>
            </div>
            {/* <Swiper {...certificationCarouselOptions} className="certificate"> */}
            <Swiper
              breakpoints={breakpoints}
              // slidesPerView={3}
              spaceBetween={15}

              className="certificate"
              modules={[Autoplay]}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {resCertificate.map((value, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="certificatebox overlay-zoom" key={index}>
                      <img
                        src={
                          value.partner_image != null
                            ? certificateImagePath + value.partner_image
                            : constant.DEFAULT_IMAGE
                        }
                        alt={value.partner_name}

                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )
      }
     
    </>
  );
}
export default Certification;
