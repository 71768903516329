import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import { validEmail, validNumber } from "./Regex";
import { Alert } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";


function Manufacturing() {

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true)
  const [successMessage, setSuccessMessage] = useState("");
  const [contactDetails, setContactDetails] = useState({
    contact_first_name: "",
    contact_last_name: "",
    contact_email: "",
    contact_mobile: "",
    contact_message: "",
  });

  setTimeout(() => {
    setLoading(false)
  }, 1000);

  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetContactForm = () => {
    setContactDetails({
      contact_first_name: "",
      contact_last_name: "",
      contact_email: "",
      contact_mobile: "",
      contact_message: "",
    });
  };
  const contactusProcess = () => {
    let counter = 0;

    if (counter === 0) {
      setErrorMessage("");
      if (contactDetails.contact_first_name.trim() === "") {
        setErrorMessage("Please enter First Name");
        return false;
      }

      if (contactDetails.contact_last_name.trim() === "") {
        setErrorMessage("Please enter Last Name");
        return false;
      }
      if (!validEmail.test(contactDetails.contact_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      } else if (!validNumber.test(contactDetails.contact_mobile)) {
        setErrorMessage("Please enter valid Mobile Number");
        return false;
      }
      ApiService.postData("contact-us-process", contactDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message);
          resetContactForm();
          setTimeout(() => {
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
        }
      });
    }
  };

  return (
    <>
      <BrowserView>
        {
          loading == true ? <>
            <section className="bg-gray">
              <div className="container-fluid px-0">
                <div className="row g-0">
                  <div className="col-lg-6 px-3">
                    <div className="manufucture-box">
                      <div class="section-title mb-3">
                        <h6><Skeleton width={200} /></h6>
                        <h2><Skeleton width={400} /></h2>
                        <p><Skeleton width={600} /></p>
                      </div>
                      <div className="features-list">
                        {
                          [...Array(3)].map((_, index) => (
                            <div className="features-box" key={index}>
                              <Skeleton width={70} height={70} borderRadius='50%' />
                              <div className="features-content mx-3">
                                <h5 class="features-title"><Skeleton width={200} height={20} /></h5>
                                <p><Skeleton /></p>
                              </div>
                            </div>

                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 px-3">
                    <div className="manfcontact bg-white">
                      <div class="section-title mb-3">
                        <h6><Skeleton width={150} /></h6>
                        <h2><Skeleton width={300} /></h2>
                        <p className="tx-white"><Skeleton width={400} /></p>
                      </div>

                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <Skeleton width='100%' height={50} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <Skeleton width='100%' height={50} />

                        </div>
                        <div className="col-lg-6">
                          <Skeleton width='100%' height={50} />

                        </div>
                        <div className="col-lg-6">
                          <Skeleton width='100%' height={50} />

                        </div>
                        <div className="col-lg-12">
                          <Skeleton width='100%' height={100} />

                        </div>
                        <div className="col-lg-12">
                          <Skeleton width={130} height={50} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></section>
          </> : <>
            <section className="bg-gray">
              <div className="container-fluid px-0">
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="manufucture-box">
                      <div class="section-title mb-3">
                        <h6>About Manufacturing</h6>
                        <h2>Manufacturing Overview</h2>
                        <span class="shape-line"><i class="icon-19"></i></span>
                        <p> Sole motto of Triveni Exports - To achieve commitment of Quality, delivery and competitive price since 1991. Operating 4 factories and 1500 workers in Jaipur. We are committed to serving our customers through design-oriented  innovation, vertically integrated operations. </p>
                      </div>
                      <div className="features-list">
                        <div className="features-box">
                          <div className="features-icon"><img src="/img/sxz.jpg" alt="Icon"/></div>
                          <div className="features-content">
                            <h5 class="features-title">Commitment and Capability</h5>
                            <p>We are not just fast, we are very fast with a knack for perfection. We can make more than 8000 pieces of beautiful designer clothes every single day.</p>
                          </div>
                        </div>
                        <div className="features-box">
                          <div className="features-icon"><img src="/img/dcc.jpg" alt="Icon"/></div>
                          <div className="features-content">
                            <h5 class="features-title">Cutting, Handiwork, Stitching</h5>
                            <p>We roll out and process more than 1500 meters of fabric every day.</p>
                          </div>
                        </div>
                        <div className="features-box">
                          <div className="features-icon"><img src="/img/xax.jpg" alt="Icon"/></div>
                          <div className="features-content">
                            <h5 class="features-title">Latest Machinery</h5>
                            <p>We are highly equipped with more than 1250 single needle Juki UBT sewing machines to produce the.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="manfcontact">
                      <div class="section-title mb-3">
                        <h6>Contact Us</h6>
                        <h2>Request a Quote Today</h2>
                        <p className="tx-white"> Fill out this form for booking a consultant advising session. </p>
                      </div>
                      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                      {successMessage && <Alert variant="success">{successMessage}</Alert>}

                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>First Name</label>
                            <input
                              name="contact_first_name"
                              type="text"
                              value={contactDetails.contact_first_name}
                              onChange={(e) => onTodoChange(e)}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>Last Name</label>
                            <input
                              name="contact_last_name"
                              type="text"
                              value={contactDetails.contact_last_name}
                              onChange={(e) => onTodoChange(e)}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>Email Id</label>
                            <input
                              name="contact_email"
                              type="text"
                              value={contactDetails.contact_email}
                              onChange={(e) => onTodoChange(e)}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>Mobile Number</label>
                            <div className="inputcountry">
                              <select>
                                <option>+91</option>
                              </select>
                              <input type="number"
                                name="contact_mobile"
                                value={contactDetails.contact_mobile}
                                onChange={(e) => onTodoChange(e)}
                              ></input>
                            </div>

                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mfomrbox">
                            <label>Message</label>
                            <textarea
                              name="contact_message"
                              value={contactDetails.contact_message}
                              onChange={(e) => onTodoChange(e)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button type="button" className="btn btn-medium btn-secondary"
                            onClick={contactusProcess}
                          >Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      </BrowserView>
      <MobileView>
        {
          loading == true ? <>
            <section className="bg-gray">
              <div className="container-fluid px-0">
                <div className="row g-0">
                  <div className="col-lg-6 px-3">
                    <div className="manufucture-box">
                      <div class="section-title mb-3">
                        <h6><Skeleton width={70} /></h6>
                        <h2><Skeleton width={100} /></h2>
                        <p><Skeleton width={150} /></p>
                      </div>
                      <div className="features-list">
                        {
                          [...Array(3)].map((_, index) => (
                            <div className="features-box" key={index}>
                              <Skeleton width={70} height={70} borderRadius='50%' />
                              <div className="features-content mx-3">
                                <h5 class="features-title"><Skeleton width={200} height={20} /></h5>
                                <p><Skeleton /></p>
                              </div>
                            </div>

                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 px-3">
                    <div className="manfcontact bg-white">
                      <div class="section-title mb-3">
                        <h6><Skeleton width={70}/></h6>
                        <h2><Skeleton width={100}/></h2>
                        <p className="tx-white"><Skeleton width={150}/></p>
                      </div>

                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <Skeleton width='100%' height={50} />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <Skeleton width='100%' height={50} />

                        </div>
                        <div className="col-lg-6">
                          <Skeleton width='100%' height={50} />

                        </div>
                        <div className="col-lg-6">
                          <Skeleton width='100%' height={50} />

                        </div>
                        <div className="col-lg-12">
                          <Skeleton width='100%' height={100} />

                        </div>
                        <div className="col-lg-12">
                          <Skeleton width={130} height={50} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></section>
          </> : <>
            <section className="bg-gray">
              <div className="container-fluid px-0">
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="manufucture-box">
                      <div class="section-title mb-3">
                        <h6>About Manufacturing</h6>
                        <h2>Manufacturing Overview</h2>
                        <span class="shape-line"><i class="icon-19"></i></span>
                        <p> Sole motto of Triveni Exports - To achieve commitment of Quality, delivery and competitive price since 1991. Operating 4 factories and 1500 workers in Jaipur. We are committed to serving our customers through design-oriented  innovation, vertically integrated operations. </p>
                      </div>
                      <div className="features-list">
                        <div className="features-box">
                          <div className="features-icon"><img src="/img/sxz.jpg" alt="Icon"/></div>
                          <div className="features-content">
                            <h5 class="features-title">Commitment and Capability</h5>
                            <p>We are not just fast, we are very fast with a knack for perfection. We can make more than 8000 pieces of beautiful designer clothes every single day.</p>
                          </div>
                        </div>
                        <div className="features-box">
                          <div className="features-icon"><img src="/img/dcc.jpg" alt="Icon"/></div>
                          <div className="features-content">
                            <h5 class="features-title">Cutting, Handiwork, Stitching</h5>
                            <p>We roll out and process more than 1500 meters of fabric every day.</p>
                          </div>
                        </div>
                        <div className="features-box">
                          <div className="features-icon"><img src="/img/xax.jpg" alt="Icon"/></div>
                          <div className="features-content">
                            <h5 class="features-title">Latest Machinery</h5>
                            <p>We are highly equipped with more than 1250 single needle Juki UBT sewing machines to produce the.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="manfcontact">
                      <div class="section-title mb-3">
                        <h6>Contact Us</h6>
                        <h2>Request a Quote Today</h2>
                        <p className="tx-white"> Fill out this form for booking a consultant advising session. </p>
                      </div>
                      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                      {successMessage && <Alert variant="success">{successMessage}</Alert>}

                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>First Name</label>
                            <input
                              name="contact_first_name"
                              type="text"
                              value={contactDetails.contact_first_name}
                              onChange={(e) => onTodoChange(e)}
                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>Last Name</label>
                            <input
                              name="contact_last_name"
                              type="text"
                              value={contactDetails.contact_last_name}
                              onChange={(e) => onTodoChange(e)}

                            >
                            </input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>Email Id</label>
                            <input
                              name="contact_email"
                              type="text"
                              value={contactDetails.contact_email}
                              onChange={(e) => onTodoChange(e)}
                            ></input>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mfomrbox">
                            <label>Mobile Number</label>
                            <div className="inputcountry">
                              <select>
                                <option>+91</option>
                              </select>
                              <input type="number"
                                name="contact_mobile"
                                value={contactDetails.contact_mobile}
                                onChange={(e) => onTodoChange(e)}
                              ></input>
                            </div>

                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mfomrbox">
                            <label>Message</label>
                            <textarea
                              name="contact_message"
                              value={contactDetails.contact_message}
                              onChange={(e) => onTodoChange(e)}

                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button type="button" className="btn btn-medium btn-secondary"
                            onClick={contactusProcess}
                          >Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      </MobileView>
    </>
  )
}

export default Manufacturing