import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import constant from "../../Components/Services/constant";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import numeral from "numeral";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../Components/Elements/mobile_header";
import { useParams } from "react-router-dom";
import moment from "moment";
import sessionCartData from "../../Components/Elements/cart_session_data";
import OrderCancelModal from "../../Components/Modals/order_cancel_modal";
function OrderDetails() {
  const didMountRef = useRef(true);
  const [rowUserData, setRowUserData] = useState({});
  const [orderData, setOrderData] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [transId, setTransId] = useState(0);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleOpenModal = (status,transid) => {
    setTransId(transid)
    setShowCancelModal(status)
  };
  
  const handleCancelModal = () => {
    setShowCancelModal(false)
  };
  useEffect(() => {
    if (didMountRef.current) {
      getuserData();
      getOrderData();
    }
    didMountRef.current = false;
  }, []);
  const getuserData = () => {
    ApiService.fetchData("get-user-data").then((res) => {
      if (res.status == "success") {
        setRowUserData(res.rowUserData);
        setSpinnerLoading(false);
      } else {
        sessionStorage.removeItem("USER_TOKEN");
        setSpinnerLoading(false);
        navigate("/");
      }
    });
  };

  const getOrderData = () => {
    const getOrderDetail = {
      trans_id: id,
    };
    ApiService.postData("get-order-detail", getOrderDetail).then((res) => {
      if (res.status == "success") {
        setOrderData(res.row_orders_data);
        setItemsData(res.row_orders_data.items);
      }
    });
  };
  const Step = ({ status, date, statustext }) => {
    const stepClasses = `col-3 bs-wizard-step ${status === 'is-done' ? 'is-done' : ''} ${
      status === 'current' ? 'current' : ''
    }`;
  
    return (
    <li className={'StepProgress-item '+stepClasses}>
      <p className="mb-0 tx-14">{statustext}</p>
      {date?<p className="tx-12 tx-color-03 mb-0">{moment(date).format("DD MMM YYYY")}</p>:''}
      
      </li>
    );
  };
  
  return (
    <>
      {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
      <Header state="inner-header" cartCount={parsedCartSession.length}/>
        <main className="main">
          <div className="subheader">
            <Container>
              <Row>
                <Col lg={12}>
                  <Breadcrumb className="breadcrumb-inner">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/account/orders">
                      My Orders
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>My Order Details</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="page-content mt-4 mb-4">
            <section>
              <Container>
                <Row>
                  <AccountSidebar rowUserData={rowUserData} />
                  <Col lg={9}>
                    <div className="acpanel">
                      <div className="acpanel-header">
                        <h4>Order Details</h4>
                      </div>
                      <div className="acpanel-body">
                        <div className="order-box">
                          <div className="info">
                            <div className="info-delivery">
                              <h6 className="mb-1">Delivery Address</h6>
                              <p className="mb-0 mt-0">
                                <strong>{orderData.trans_user_name}</strong>
                              </p>
                              <p className="tx-12">
                                {orderData.trans_delivery_address}
                              </p>
                              <p className="mb-0 mt-0">
                                <strong>Email Id :</strong>
                                {orderData.trans_user_email}
                              </p>
                              <p className="mb-0 mt-0">
                                <strong>Phone number :</strong>
                                {orderData.trans_user_mobile}
                              </p>
                              <p className="mb-5">
                                <strong>Txn Id:</strong>
                                {orderData.trans_order_number}
                              </p>
                              <p className="mb-0 mt-0">
                                <strong>Order Status:</strong>
                                <span className="badge bg-success">
                                  {orderData.orderstatus}
                                </span>
                              </p>
                              {orderData.trans_cancellation_date ? (
                                <p className="mb-5">
                                  <strong>Order Cancelled Date:</strong>
                                  {moment(orderData.trans_cancellation_date).format(
                                "ddd, DD MMM YYYY"
                              )}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="bcode">
                            <div className="orderid-box mb-20">
                              <h6>ORDER ID</h6>
                              <p className="mb-0">
                                {orderData.trans_order_number}
                              </p>
                            </div>
                            <p className="tx-color-03 mb-0">ORDER ON</p>
                            <p className="tx-12">
                              {moment(orderData.trans_datetime).format(
                                "ddd, DD MMM YYYY"
                              )}
                              <br />
                              {moment(orderData.trans_datetime, "HH:mm").format(
                                "hh:mm A"
                              )}
                            </p>
                             {orderData.trans_status === 1 || orderData.trans_status === 2 || orderData.trans_status === 3 ?
                            <a
                                    href="javascript:void(0)"
                                    className="btn btn-primary-outline btn-medium btn-block"
                                    onClick={(e) => handleOpenModal(true,orderData.trans_id)}
                                  > 
                                    Cancel Order
                                  </a>:null} 
                          </div>
                        </div>

                        <div className="card-table">
                          <div className="card-table-header">
                            Order Items List
                          </div>

                          <div className="card-table-section">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th className="text-center">S.No</th>
                                  <th>Image</th>
                                  <th>Title</th>
                                  <th className="text-center">QTY</th>
                                  <th className="text-center">Price (GST Included)</th>
                                  <th className="text-center">Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {itemsData.map((value, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {index + 1}
                                      </td>

                                      <td className="text-center">
                                        <img
                                          src={value.td_item_image}
                                          alt={value.td_item_image}
                                          style={{ width: "35px" }}
                                        />
                                      </td>
                                      <td>
                                        {value.td_item_title}
                                        <br />
                                      </td>
                                      <td className="text-center">
                                        {value.td_item_qty}
                                      </td>
                                      <td className="text-center">
                                      {orderData.trans_currency}
                                        {numeral(
                                          value.td_item_sellling_price
                                        ).format("0,0.00")}
                                      </td>
                                      <td className="text-center">
                                        {orderData.trans_currency}
                                        {numeral(
                                          value.td_item_sellling_price *
                                            value.td_item_qty
                                        ).format("0,0.00")}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan="3"></td>
                                  <td colSpan=""></td>
                                  <td>Sub Total</td>
                                  <td className="text-center">
                                    {orderData.trans_currency}
                                    {numeral(orderData.item_sub_total).format(
                                      "0,0.00"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="4"></td>
                                  <td>Discount</td>
                                  <td className="text-center">
                                    -{orderData.trans_currency}
                                    {numeral(
                                      orderData.trans_discount_amount
                                    ).format("0,0.00")}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="4"></td>
                                  <td>Coupon Discount</td>
                                  <td className="text-center">
                                    {orderData.trans_currency}
                                    {numeral(
                                      orderData.trans_coupon_dis_amt
                                    ).format("0,0.00")}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="4"></td>
                                  <td>Delivery Charges</td>

                                  <td className="text-center">
                                    {orderData.trans_currency}
                                    {numeral(
                                      orderData.trans_delivery_amount
                                    ).format("0,0.00")}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="4"></td>
                                  <td>
                                    <strong>Grand total</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>{orderData.trans_currency}{orderData.trans_amt}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </main>
        <Footer />
      </BrowserView>

      <MobileView>
        <MobileHeader PageName={"#"+orderData.trans_order_number} Route="account/orders" cartCount={parsedCartSession.length}/>
        <div className="panel p-3">
        <div className="panel-header mb-3">Shipping & Billing Address</div>
        <p className="mb-0 mt-0">
                  <strong>{orderData.trans_user_name}</strong>
                </p>
                <p className="tx-12">{orderData.trans_delivery_address}</p>
                <p className="mb-0 mt-0">
                  <strong>Email Id: </strong>
                  {orderData.trans_user_email}
                </p>
                <p className="mb-0 mt-0">
                  <strong>Phone No: </strong>
                  {orderData.trans_user_mobile}
                </p>
        </div>
        <div className="spacer1"></div>
        <div className="panel vsteprocess">
      
{(() => {
      if(orderData.trans_status == 1){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="" date="" statustext="Item Picked Up"/>
          <Step status="" date="" statustext="Shipped"/>
          <Step status="" date="" statustext="Delivered"/>
          </ul> 
        )
      }else if(orderData.trans_status == 4){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="is-done" date={orderData.trans_pickedup_date} statustext="Item Picked Up"/>
          <Step status="is-done" date={orderData.trans_shiped_date} statustext="Shipped"/>
          <Step status="is-done" date={orderData.trans_delivered_date} statustext="Delivered"/>
          </ul> 
        )
      }else if(orderData.trans_status == 5){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="is-done" date={orderData.trans_cancelled_date} statustext="Cancelled"/>
          </ul> 
        )
      }else if(orderData.trans_status == 6){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="is-done" date={orderData.trans_pickedup_date} statustext="Item Picked Up"/>
          <Step status="is-done" date={orderData.trans_shiped_date} statustext="Shipped"/>
          <Step status="" date={orderData.trans_delivered_date} statustext="Delivered"/>
          </ul> 
        )
      }else if(orderData.trans_status == 7){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="is-done" date={orderData.trans_pickedup_date} statustext="Item Picked Up"/>
          <Step status="" date={orderData.trans_shiped_date} statustext="Shipped"/>
          <Step status="" date={orderData.trans_delivered_date} statustext="Delivered"/>
          </ul> 
        )
      }else if(orderData.trans_status == 2){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="is-done" date={orderData.trans_pendingpayment_date} statustext="Payment Pending"/>
          <Step status="" date={orderData.trans_shiped_date} statustext="Shipped"/>
          <Step status="" date={orderData.trans_delivered_date} statustext="Delivered"/>
          </ul> 
        )
      }else if(orderData.trans_status == 3){
        return(
          <ul className="StepProgress">
          <Step status="is-done" date={orderData.created_at} statustext="Order Placed" />
          <Step status="is-done" date={orderData.trans_onhold_date} statustext="On Hold"/>
          <Step status="" date={orderData.trans_shiped_date} statustext="Shipped"/>
          <Step status="" date={orderData.trans_delivered_date} statustext="Delivered"/>
          </ul> 
        )
      }

    })()}

        </div>
        <div className="spacer1"></div>
        <div className="panel">
          {itemsData.map((value, index) => {
            return (
              <div className="oddetails-item" key={index}>
          <div className="oddetails-item-media">
      <img src={value.td_item_image} alt={value.td_item_image} />
      </div>
            <div className="oddetails-item-content">
              <h6 className="mb-1 tx-13">{value.td_item_title}</h6>
              <div className="price">
              {value.td_item_unit &&  <p className="tx-12 mb-1">Weight: {value.td_item_unit}</p>}
                <p className="tx-12 mb-0">₹{value.td_item_total}</p>
              </div>
            </div>
          </div>
          );
        })}
        </div>
        <div className="spacer1"></div>
        <div className="panel checkoutlist">
          <div className="panel-header">Price Details</div>
          <div className="panel-body">
            <div className="pcb-list mt-2">
              <ul><li>Item Total<span className="ml-auto">{orderData.trans_currency}
                                    {numeral(orderData.item_sub_total).format(
                                      "0,0.00"
                                    )}</span></li>
              <li>Discount<span className="ml-auto tx-green">-{orderData.trans_currency}
                                    {numeral(
                                      orderData.trans_discount_amount
                                    ).format("0,0.00")}</span></li>
              <li>Coupon Discount<span className="ml-auto tx-green">{orderData.trans_currency}
                                    {numeral(
                                      orderData.trans_coupon_dis_amt
                                    ).format("0,0.00")}</span></li>
              <li> Shipping<span className="ml-auto tx-green">{orderData.trans_currency}
                                    {numeral(
                                      orderData.trans_delivery_amount
                                    ).format("0,0.00")}</span></li>
                                    </ul>
              </div>
              <hr/>
              <div className="pcb-list-second">
                <ul>
                  <li>Total Amount<span className="ml-auto">{orderData.trans_currency}{orderData.trans_amt}</span></li></ul>
                </div>
                <hr/>
               </div>
               </div>
      </MobileView>
      {showCancelModal && (
        <OrderCancelModal
          transId={transId}
          showmodal={showCancelModal}
          onChildData={setShowCancelModal}
          
          closeModal={handleCancelModal}
        />
      )}
    </>
  );
}
export default OrderDetails;
