import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Helmet } from "react-helmet";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrowserView, MobileView } from "react-device-detect";
function Blog() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [blogData, setBlogData] = useState([]);
  const [blogImagePath, setBlogImagePath] = useState([]);
  const [loading, setLoading] = useState()
  useEffect(() => {
    if (didMountRef.current) {
      setLoading(true)
      getBlogData();
      const getPageData = {
        slug: "blogs",
      };
      ApiService.postData("page-content", getPageData).then(
        (res) => {
          if (res.status == "success") {
            setPageData(res.data);
            setLoading(false)
          }
          else {
            setLoading(false)
          }
        })
    }




    didMountRef.current = false;
  });

  const getBlogData = () => {
    ApiService.fetchData("blog-list").then((res) => {
      if (res.status == "success") {
        setBlogData(res.blogsData);
        setBlogImagePath(res.blog_image_path);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : ""} />
        {pageData.page_meta_keyword != null ? <meta name="keywords" content={pageData.page_meta_keyword} /> : ""}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
        <meta property="og:image" content={constant.FRONT_URL + 'img/logo.png'} />
        <meta property="og:url" content={window.location.href} />
        {pageData.page_meta_desc != null ? <meta property="og:description" content={pageData.page_meta_desc} /> : ""}
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData.page_meta_desc != null ? <meta name="twitter:description" content={pageData.page_meta_desc} /> : ""}
        <meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />

      </Helmet>
      <BrowserView>
        <Header state="inner-header" />
        <div className="subheader" style={{background:'url(/img/subheader.webp)'}}>
          <div className="subheader-overlay"></div>
          <div className="subheader-content">
                <h1>{pageData.page_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                  <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                </Breadcrumb>
                </div>

        </div>
        {
          loading == true ? <>
            <section className="sec-pad pt-0">
              <div className="container">

                <div className="row">
                  {
                    [...Array(5)].map((_, index) => (
                      <div className="col-lg-4 mt-5" key={index}>
                        <Skeleton width='100%' height={300} />

                        <div className="d-flex mt-2 align-items-center">
                          <span><Skeleton width={30} height={20} borderRadius='30%' /></span>
                          &nbsp;
                          <span><Skeleton width={80} /></span>
                          &nbsp;
                          <span><Skeleton width={30} height={20} borderRadius='50%' /></span>
                          &nbsp;
                          <span><Skeleton width={60} /></span>
                        </div>
                        <h4 className="mt-2">
                          <Skeleton width='80%' height={30} />
                        </h4>
                        <p className="mt-2">
                          <Skeleton width='70%' height={15} />
                        </p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          </> : blogData && blogData.length > 0 && (
            <section className="sec-pad">
              <Container>
                <Row>
                  {blogData.map((value, index) => (
                    <Col lg={4} className="mb-5" key={index}>
                      <div className="listbog">
                        <div className="thumbnail">
                          <a href={`/blogs/${value.blog_slug}`}>
                            <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image} />
                          </a>
                        </div>
                        <div className="content">
                          <div className="read-more-btn">
                            <a className="btn-icon-round" href={`/blogs/${value.blog_slug}`}><i className="d-icon-arrow-right"></i></a>
                          </div>
                          <ul className="blog-meta">
                            <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-calendar-alt"></i>{moment(value.created_at).format('MMM D, YYYY')}</a>
                            </li>
                            <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-user"></i>Admin</a></li>
                          </ul>
                          <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                          <p>{value.blog_short_description}</p>
                        </div>
                      </div>
                    </Col>
                  ))}

                </Row>
              </Container>
            </section>
          )}
      </BrowserView>

      <MobileView>
        <Header state="inner-header" />
        <div className="subheader">
          <Container>
            <Row>

              <Col lg={12}>
                <h1>{pageData.page_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                  <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                </Breadcrumb>


              </Col>
            </Row>
          </Container>
        </div>
        {
          loading == true ? <>
            <section className="sec-pad">
              <div className="container">

                <div className="row ">
                  {
                    [...Array(5)].map((_, index) => (
                      <div className="col-lg-4 mt-5" key={index}>
                        <Skeleton width='100%' height={300} />

                        <div className="d-flex mt-2 align-items-center">
                          <span><Skeleton width={30} height={20} borderRadius='30%' /></span>
                          &nbsp;
                          <span><Skeleton width={80} /></span>
                          &nbsp;
                          <span><Skeleton width={30} height={20} borderRadius='50%' /></span>
                          &nbsp;
                          <span><Skeleton width={60} /></span>
                        </div>
                        <h4 className="mt-2">
                          <Skeleton width='80%' height={30} />
                        </h4>
                        <p className="mt-2">
                          <Skeleton width='70%' height={15} />
                        </p>

                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          </> : blogData && blogData.length > 0 && (
            <section className="sec-pad">
              <Container>
                <Row>
                  {blogData.map((value, index) => (
                    <Col lg={4} className="mb-5" key={index}>
                      <div className="listbog">
                        <div className="thumbnail">
                          <a href={`/blogs/${value.blog_slug}`}>
                            <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image} />
                          </a>
                        </div>
                        <div className="content">
                          <div className="read-more-btn">
                            <a className="btn-icon-round" href={`/blogs/${value.blog_slug}`}><i className="d-icon-arrow-right"></i></a>
                          </div>
                          <ul className="blog-meta">

                            <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-calendar-alt"></i>{moment(value.created_at).format('MMM D, YYYY')}</a>
                            </li>
                            <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-user"></i>Admin</a></li>
                          </ul>
                          <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>

                          <p>{value.blog_short_description}</p>

                        </div>
                      </div>
                    </Col>
                  ))}

                </Row>
              </Container>
            </section>
          )}
      </MobileView>
      <Footer />
    </>
  )
}
export default Blog
