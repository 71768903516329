import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Alert from "react-bootstrap/Alert";

function EnquiryModal({
  showmodal,
  onChildData
}) {


  const didMountRef = useRef(true);

  const [show, setShow] = useState(showmodal);

  const handleClose = () => {
    setShow(false);
    onChildData(false);
  }
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [enquiryDetails, setEnquiryDetails] = useState({
    enquiry_name: "",
    enquiry_mobile:"",
    enquiry_email: "",
    enquiry_quantity: "",
    enquiry_company: "",
    enquiry_message: "",
  });
  useEffect(() => {
    if (didMountRef.current) {

    }
    didMountRef.current = false;
  }, []);


  const enquiryProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (!validNumber.test(enquiryDetails.enquiry_mobile)) {
        setErrorMessage('Invalid Mobile number. Please enter a 10-digit number.');
        return;
      }
      if (!validEmail.test(enquiryDetails.enquiry_email)) {
        setErrorMessage("Please enter valid email");
        return false;
      }

      ApiService.postData("enquiry-process", enquiryDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message);
          //   resetThankYouForm();

          setTimeout(() => {
            setSuccessMessage("");
            window.location.reload()
          }, 4000);

        } else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
    }
  }

  // const territories = [
  //   "USA / Canada",
  //   "United Kingdom",
  //   "Europe",
  //   "Australia / New Zealand",
  //   "India",
  //   "Asia Pacific",
  //   "Middle East & Africa",
  //   "Latin America",
  // ];
  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setEnquiryDetails((prevState) => ({
      ...prevState,

      [name]: value,
    }));
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} className="">
        <button onClick={handleClose} className="pop-close "></button>

        <Modal.Body>
          <Modal.Title className="tx-theme mb-3">Quick Enquiry</Modal.Title>

          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && (
            <Alert variant="success">{successMessage}</Alert>
          )}
          <div className="row g-3">
          <div className="col-lg-12">
              <div className="form-group mb-3">

                <input
                  type="text"
                  name="enquiry_company"
                  className="form-control "
                  value={enquiryDetails.enquiry_company}
                  onChange={(e) => onTodoChange(e)}
                  placeholder="Company Name"

                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="enquiry_name"
                  className="form-control required"
                  value={enquiryDetails.enquiry_name}
                  onChange={(e) => onTodoChange(e)}
                  placeholder="Full Name*"
                />
              </div>

            </div>
            <div className="col-lg-12">
            <div className="form-group mb-3">
              <input
                type="number"
                name="enquiry_mobile"
                className="form-control "
                value={enquiryDetails.enquiry_mobile}
                onChange={(e) => onTodoChange(e)}
                placeholder="Mobile Number*"
        
              />
            </div>  
            </div>

            <div className="col-lg-12">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="enquiry_email"
                  className="form-control "
                  value={enquiryDetails.enquiry_email}
                  onChange={(e) => onTodoChange(e)}
                  placeholder="Work Email*"


                />
              </div>
            </div>
            <div className="form-group mb-3">
              <select className="form-control" name="enquiry_quantity" value={enquiryDetails.enquiry_quantity}
                onChange={(e) => onTodoChange(e)} required="required" aria-required="true" fdprocessedid="mxm1s">

                <option value="">MOQ Per Style Per Color*</option>
                <option value="0-200">0-200</option>
                <option value="200-500">200-500</option>
                <option value="500-1000">500-1000</option>
                <option value="1000-3000">1000-3000</option>
                <option value="5000+">5000+</option>

              </select>
            </div>

            {/* <div className="form-group mb-3">
              <select className="form-control" name="enquiry_territory" value={enquiryDetails.enquiry_territory}
                onChange={(e) => onTodoChange(e)} required="required" aria-required="true" fdprocessedid="studlf">

                <option value="">Territory of Operations*</option>
                {territories.map((territory, index) => (
                  <option key={index} value={territory}>
                    {territory}
                  </option>
                ))}



              </select>
            </div> */}

          
            <div className="form-group-modal mb-3">
              <textarea
                name="enquiry_message"
                className="form-control "
                value={enquiryDetails.enquiry_message}
                onChange={(e) => onTodoChange(e)}
                placeholder="Message"

              />
            </div>

            <div className="d-grid mb-4">
              <Button className="btn btn-primary-outline btn-medium" onClick={enquiryProcess}>Submit</Button>
            </div>
          </div>
        </Modal.Body>


      </Modal>
    </>
  )
}

export default EnquiryModal