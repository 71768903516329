import React, { useCallback, useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import Skeleton from 'react-loading-skeleton'
import { BrowserView, MobileView } from "react-device-detect";

function FeaturedCategories() {
    const didMountRef = useRef(true);
    const sliderRef = useRef(null);
    const [resCategoryData, setResCategoryData] = useState([]);
    const [CategoryImagePath, setCategoryImagePath] = useState('');
    const [loading, setLoading] = useState()

    const catCarouselOptions = {
        loop: true,
        spaceBetween: 5,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            ref: sliderRef
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 3,
            },
        },
    };

    const handlePrev = useCallback(() => {
        console.log("Previous button clicked");
        if (sliderRef.current) {
            sliderRef.current.slidePrev();
        }
    }, []);
    const handleNext = useCallback(() => {
        console.log("Next button clicked");
        if (sliderRef.current && sliderRef.current.slideNext) {
            sliderRef.current.slideNext();
        }
    }, []);



    useEffect(() => {
        if (didMountRef.current) {
            setLoading(true)
            getCategoryData();
        }
        didMountRef.current = false;
    }, []);
    const getCategoryData = () => {
        ApiService.fetchData("featured-category").then((res) => {
            console.log(res)
            if (res.status == "success") {
                setResCategoryData(res.resCategory);
                setCategoryImagePath(res.category_image_path);
                setLoading(false)
            }
            else {
                setLoading(setLoading)
            }
        });
    };

    return (
        <>
            <BrowserView>
                {loading == true ? (
                    <section className="sec-pad pt-5" >
                        <div className="container-fluid">
                            <div className="row text-center">
                                <div className="col">
                                    <Skeleton variant="text" width={250} height={30} />
                                    <Skeleton variant="text" width={450} height={25} />
                                </div>
                            </div>
                            <div className="row mt-4">
                                {
                                    [...Array(3)].map((_, index) => {
                                        return (
                                            <div className="col-lg-4 col-6" key={index}>
                                                <div className="product">
                                                    <figure className="product-media">
                                                        <Skeleton variant="text" width='100%' height={300} />
                                                    </figure>

                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>) : resCategoryData.length > 0 && (
                        <section className="sec-pad-large">
                            <div className="container-fluid">
                                <div className="section-title text-center mb-5">
                                    <h2 className="mb-0">Our Collection</h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                    <p>We are committed to serving our customers through design-oriented  innovation, vertically integrated operations. </p>
                                </div>
                            </div>
                            <Swiper {...catCarouselOptions} onSwiper={(swiper) => (sliderRef.current = swiper)}>

                                {resCategoryData.map((value, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="caategory">
                                            <a href={"/collection/category/" + value.cat_slug} key={index}>
                                                <div className="caategory-media">
                                                    <img src={value.cat_image != null ? CategoryImagePath + value.cat_image : constant.DEFAULT_IMAGE
                                                    }
                                                        alt={value.cat_name}
                                                    />
                                                </div>
                                                <div className="caategory-content">
                                                    <div className="caategory-name">{value.cat_name}</div>
                                                    <a href={"/collection/category/" + value.cat_slug} className="btn btn-primary btn-medium">View More</a>
                                                </div>

                                            </a>
                                        </div>
                                    </SwiperSlide>
                                ))}

                                <div className="swiper-button-next" onClick={handleNext}></div>
                                <div className="swiper-button-prev" onClick={handlePrev}></div>
                            </Swiper>
                        </section>
                    )
                }
            </BrowserView>
            <MobileView>
                {loading == true ? (
                    <section className="sec-pad mt-5" >
                        <div className="container">
                            <div className="row text-center">
                                <div className="col">
                                    <Skeleton variant="text" width={250} height={30} />
                                    <Skeleton variant="text" width={350} height={25} />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <Swiper>
                                    {
                                        [...Array(3)].map((_, index) => {
                                            return (
                                                <SwiperSlide>
                                                    <div className="col-lg-4 col mx-2" key={index}>
                                                        <div className="product">
                                                            <figure className="product-media">
                                                                <Skeleton variant="text" width='100%' height={300} />
                                                            </figure>

                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </section>) : resCategoryData.length > 0 && (
                        <section className="sec-pad-large">
                            <div className="container-fluid">
                                <div className="section-title text-center mb-5">
                                    <h2 className="mb-0">Our Collection</h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                    <p>We are committed to serving our customers through design-oriented  innovation, vertically integrated operations. </p>
                                </div>
                            </div>
                            <Swiper {...catCarouselOptions} onSwiper={(swiper) => (sliderRef.current = swiper)}>

                                {resCategoryData.map((value, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="caategory">
                                            <a href={"/collection/category/" + value.cat_slug} key={index}>
                                                <div className="caategory-media">
                                                    <img src={value.cat_image != null ? CategoryImagePath + value.cat_image : constant.DEFAULT_IMAGE
                                                    }
                                                        alt={value.cat_name}
                                                    />
                                                </div>
                                                <div className="caategory-content">
                                                    <div className="caategory-name">{value.cat_name}</div>
                                                    <a href={"/collection/category/" + value.cat_slug} className="btn btn-primary btn-medium">View More</a>
                                                </div>

                                            </a>
                                        </div>
                                    </SwiperSlide>
                                ))}

                                <div className="swiper-button-next" onClick={handleNext}></div>
                                <div className="swiper-button-prev" onClick={handlePrev}></div>
                            </Swiper>
                        </section>
                    )
                }
            </MobileView>
        </>
    );
}
export default FeaturedCategories;
