import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { Accordion } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
const FAQS = () => {
    const [faqData, setFaqData] = useState([])
    const [pageData, setPagedata] = useState('')
    const didMountRef = useRef(true)
    const [loading, setLoading] = useState()

    useEffect(() => {

        if (didMountRef.current) {
            setLoading(true)
            const pagedata = {
                slug: 'faqs'
            }

            ApiService.postData('page-content', pagedata).then((res) => {
                console.log(res)
                if (res.status === 'success') {
                    setPagedata(res.data)
                }
            })
            ApiService.fetchData('faqs').then((res) => {
                console.log(res)
                if (res.status === 'success') {
                    setFaqData(res?.faqData)
                    setLoading(false)
                }
            })

        }
        didMountRef.current = false
    }, [])


    return (
        <>
            <Header />
            <div className="subheader" style={{background:'url(/img/subheader.webp)'}}>
          <div className="subheader-overlay"></div>
          <div className="subheader-content">
                <h1>{pageData.page_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                  <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                </Breadcrumb>
                </div>

        </div>
            {
                loading == true ? <>
                    <section className="sec-pad">
                        <div className="container">
                            <div className="row">
                                {
                                    [...Array(7)].map((_, index) => (

                                        <div className="col-lg-12 mb-2" key={index}>
                                            <Skeleton width='100%' height={50} />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                </> : faqData && faqData.length > 0 && (
                    <section className="sec-pad">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className='mb-5'>
                                        <Accordion>
                                            {faqData.map((value, index) => {
                                                return (
                                                    <Accordion.Item key={index} eventKey={index} className="checkout-accord">
                                                        <Accordion.Header>
                                                            <h6 className="mb-0 tx-14">{value.faq_title}</h6>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="order-notetextarea">
                                                                <div className='faq-para' dangerouslySetInnerHTML={{ __html: value.faq_description }}></div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                );
                                            })}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
            <Footer />
        </>
    )
}

export default FAQS