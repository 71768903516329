import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { ApiService } from "../Services/apiservices";

import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";

function Blogs() {
    const didMountRef = useRef(true);

    const [blogData, setBlogData] = useState([])
    const [blogImagePath, setBlogImagePath] = useState('')
    const [loading, setLoading] = useState()
    useEffect(() => {

        if (didMountRef.current) {
            setLoading(true)
            getBlogData()
        }
        didMountRef.current = false;

    }, [])


    const getBlogData = () => {

        ApiService.fetchData("featured-blogs").then((res) => {
            if (res.status == "success") {
                setBlogData(res.blogsData);
                setBlogImagePath(res.blog_image_path);
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        });


    }
    const blogsCarouselOptions = {
        loop: true,
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 1,
            },
            1000: {
                slidesPerView: 3,
            },
        },
    };

    return (
        <>
            <BrowserView>
                {
                    loading == true ? <>
                        <section className="sec-pad pt-5">
                            <div className="container">
                                <div className="row text-center">
                                    <div className="col">
                                        <Skeleton variant="text" width={150} height={20} />
                                        <Skeleton variant="text" width={450} height={25} />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <Swiper {...blogsCarouselOptions}>
                                        {
                                            [...Array(4)].map((_, index) => (
                                                <SwiperSlide>
                                                    <div className="col" key={index}>
                                                        <Skeleton width='100%' height={300} />
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <span><Skeleton width={30} height={20} borderRadius='30%' /></span>
                                                            &nbsp;
                                                            <span><Skeleton width={80} /></span>
                                                            &nbsp;
                                                            <span><Skeleton width={30} height={20} borderRadius='50%' /></span>
                                                            &nbsp;
                                                            <span><Skeleton width={60} /></span>
                                                        </div>
                                                        <h4 className="mt-2">
                                                            <Skeleton width='80%' height={30} />
                                                        </h4>
                                                        <p className="mt-2">
                                                            <Skeleton width='70%' height={15} />
                                                        </p>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </section>
                    </> : blogData && blogData.length > 0 && (
                        <section className="sec-pad">
                            <div className="container">
                                <div className="section-title text-center mb-5">
                                    <div className="subtitle">Latest Articles</div>
                                    <h2>Latest News & Blogs</h2>
                                    <span class="shape-line"><i class="icon-19"></i></span>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Swiper
                                            {...blogsCarouselOptions}
                                            modules={[Autoplay]}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                        >
                                            {blogData.map((value, index) => {
                                                return (
                                                    <SwiperSlide>
                                                        <div className="listbog">
                                                            <div className="thumbnail">
                                                                <a href={`/blogs/${value.blog_slug}`}>
                                                                    <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image} />
                                                                </a>
                                                            </div>
                                                            <div className="content">
                                                                <div className="read-more-btn">
                                                                    <a className="btn-icon-round" href={`/blogs/${value.blog_slug}`}><i className="d-icon-arrow-right"></i></a>
                                                                </div>
                                                                <ul className="blog-meta mb-2">
                                                                    <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-calendar-alt"></i>{moment(value.created_at).format('MMM D, YYYY')}</a>
                                                                    </li>
                                                                    <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-user"></i>Admin</a></li>
                                                                </ul>
                                                                <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                                                                <p>{value.blog_short_description}</p>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            </BrowserView>
            <MobileView>
                {
                    loading == true ? <>
                        <section className="sec-pad pt-5">
                            <div className="container">
                                <div className="row text-center">
                                    <div className="col">
                                        <Skeleton variant="text" width={150} height={20} />
                                        <Skeleton variant="text" width={350} height={25} />
                                    </div>
                                </div>
                                <div className="row mt-5 px-2">
                                    <Swiper {...blogsCarouselOptions}>
                                        {
                                            [...Array(4)].map((_, index) => (
                                                <SwiperSlide>
                                                    <div className="col" key={index}>
                                                        <Skeleton width='100%' height={300} />
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <span><Skeleton width={30} height={20} borderRadius='30%' /></span>
                                                            &nbsp;
                                                            <span><Skeleton width={80} /></span>
                                                            &nbsp;
                                                            <span><Skeleton width={30} height={20} borderRadius='50%' /></span>
                                                            &nbsp;
                                                            <span><Skeleton width={80} /></span>
                                                        </div>
                                                        <h4 className="mt-2">
                                                            <Skeleton width='80%' height={30} />
                                                        </h4>
                                                        <p className="mt-2">
                                                            <Skeleton width='70%' height={15} />
                                                        </p>

                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </section>
                    </> : blogData && blogData.length > 0 && (
                        <section className="sec-pad">
                            <div className="container">
                                <div className="section-title text-center mb-5">
                                    <div className="subtitle">Latest Articles</div>
                                    <h2>Latest News & Blogs</h2>
                                    <span class="shape-line"><i class="icon-19"></i></span>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Swiper
                                            {...blogsCarouselOptions}
                                            modules={[Autoplay]}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: false,
                                            }}
                                        >
                                            {blogData.map((value, index) => {
                                                return (
                                                    <SwiperSlide>
                                                        <div className="listbog">
                                                            <div className="thumbnail">
                                                                <a href={`/blogs/${value.blog_slug}`}>
                                                                    <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image} />
                                                                </a>
                                                            </div>
                                                            <div className="content">
                                                                <div className="read-more-btn">
                                                                    <a className="btn-icon-round" href={`/blogs/${value.blog_slug}`}><i className="d-icon-arrow-right"></i></a>
                                                                </div>
                                                                <ul className="blog-meta mb-2">
                                                                    <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-calendar-alt"></i>{moment(value.created_at).format('MMM D, YYYY')}</a>
                                                                    </li>
                                                                    <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-user"></i>Admin</a></li>
                                                                </ul>
                                                                <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                                                                <p>{value.blog_short_description}</p>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                }
            </MobileView>
        </>
    )
}

export default Blogs