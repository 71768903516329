import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton'
import { Autoplay } from "swiper/modules";
function Testimonials() {
    const didMountRef = useRef(true);
    const [resTestimonialData, setResTestimonialData] = useState([]);
    const [testimonialImagePath, setTestimonialImagePath] = useState([]);
    const [loading, setLoading] = useState()
    const testimonialCarouselOptions = {
        loop: true,
        spaceBetween: 25,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 3,
            },
            1000: {
                slidesPerView: 2,
            },
        },
    };

    useEffect(() => {
        if (didMountRef.current) {
            getTestimonialData();
        }
        didMountRef.current = false;
    }, []);
    const getTestimonialData = () => {
        setLoading(true)
        ApiService.fetchData("featured-testimonial").then((res) => {
            if (res.status == "success") {
                setResTestimonialData(res.resTestimonialData);
                setTestimonialImagePath(res.testimonial_image_path);
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        });
    };

    return (
        <>

            {
                loading == true ? <>
                    <section className="sec-pad-large bg-gray">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="section-title mb-3">
                                        <h6><Skeleton width={100} /></h6>
                                        <h2><Skeleton width={300} /></h2>
                                        <p><Skeleton width={400} /></p>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <Swiper {...testimonialCarouselOptions}>
                                        {
                                            [...Array(3)].map((_, index) => (

                                                <SwiperSlide key={index}>
                                                    <div className="testimonial-grid bg-white p-5" >
                                                        <div> <Skeleton width='100%' />
                                                            <Skeleton width='100%' />
                                                            <Skeleton width='100%' /></div>
                                                        <div className="d-flex justify-content-start mt-4">
                                                            <div className="thumbnail">
                                                                <Skeleton borderRadius='50%' height={60} width={70} />
                                                            </div>
                                                            <div className="m-4">
                                                                <h6><Skeleton width={100} /></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </section>
                </> : resTestimonialData.length > 0 && (
                    <section className="sec-pad-large bg-gray">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="section-title mb-3">
                                        <h6>Testimonials</h6>
                                        <h2>What Our Client Say</h2>
                                        <span class="shape-line"><i class="icon-19"></i></span>
                                        <p> Triveni Exports deliver stability, better flow of profit and provide high satisfaction to the clients.</p>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <Swiper {...testimonialCarouselOptions} modules={[Autoplay]}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }} className="testimonial">
                                        {resTestimonialData.map((value, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="testimonial-grid" key={index}>
                                                        <div
                                                            className="content"
                                                            dangerouslySetInnerHTML={{
                                                                __html: value.testimonial_desc,
                                                            }}
                                                        ></div>
                                                        <div className="d-flex justify-content-start mt-4">
                                                            <div className="thumbnail">
                                                                <img
                                                                    src={
                                                                        value.testimonial_image != null
                                                                            ? testimonialImagePath +
                                                                            "/" +
                                                                            value.testimonial_image
                                                                            : constant.DEFAULT_IMAGE
                                                                    }
                                                                    alt="Testimonial"
                                                                />
                                                            </div>
                                                            <div className="m-4">
                                                                <h6>{value.testimonial_name}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

        </>
    );
}
export default Testimonials;
